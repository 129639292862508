@font-face {
    font-family: 'lae-icomoon';
    src:
            url('fonts/lae-icomoon.ttf?cdhgky') format('truetype'),
            url('fonts/lae-icomoon.woff?cdhgky') format('woff'),
            url('fonts/lae-icomoon.svg?cdhgky#lae-icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    }

[class^="lae-icon-"], [class*=" lae-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'lae-icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

.lae-icon-arrow-left:before {
    content: "\e900";
    }
.lae-icon-arrow-right:before {
    content: "\e901";
    }
.lae-icon-aim:before {
    content: "\e902";
    }
.lae-icon-behance:before {
    content: "\e903";
    }
.lae-icon-dribbble:before {
    content: "\e904";
    }
.lae-icon-facebook:before {
    content: "\e905";
    }
.lae-icon-flickr:before {
    content: "\e906";
    }
.lae-icon-googleplus:before {
    content: "\e907";
    }
.lae-icon-linkedin:before {
    content: "\e908";
    }
.lae-icon-pinterest:before {
    content: "\e909";
    }
.lae-icon-skype:before {
    content: "\e90a";
    }
.lae-icon-twitter:before {
    content: "\e90b";
    }
.lae-icon-vimeo:before {
    content: "\e90c";
    }
.lae-icon-zerply:before {
    content: "\e90d";
    }
.lae-icon-quote:before {
    content: "\e90e";
    }
.lae-icon-video-play:before {
    content: "\e90f";
    }
.lae-icon-email:before {
    content: "\e910";
    }
.lae-icon-close:before {
    content: "\e911";
    }
.lae-icon-plus:before {
    content: "\e912";
    }
.lae-icon-arrow-right-toggle:before {
    content: "\e913";
    }
.lae-icon-menu:before {
    content: "\e914";
    }
.lae-icon-menu-2:before {
    content: "\e915";
    }
.lae-icon-fit-to:before {
    content: "\e916";
    }
.lae-icon-full-screen:before {
    content: "\e917";
    }
.lae-icon-arrow-left2:before {
    content: "\e918";
    }
.lae-icon-arrow-left3:before {
    content: "\e919";
    }
.lae-icon-arrow-right2:before {
    content: "\e91a";
    }
.lae-icon-arrow-right3:before {
    content: "\e91b";
    }
.lae-icon-start:before {
    content: "\e91c";
    }
.lae-icon-instagram:before {
    content: "\ea92";
    }
.lae-icon-heart:before {
    content: "\e91d";
    }
.lae-icon-love:before {
    content: "\e91d";
    }
.lae-icon-like:before {
    content: "\e91d";
    }
.lae-icon-retweet:before {
    content: "\e91e";
    }
.lae-icon-arrows:before {
    content: "\e91e";
    }
.lae-icon-loop:before {
    content: "\e91e";
    }
.lae-icon-reload:before {
    content: "\e91e";
    }
.lae-icon-play2:before {
    content: "\ea15";
    }
.lae-icon-play3:before {
    content: "\ea1c";
    }
.lae-icon-twitter2:before {
    content: "\ea96";
    }
